import React, { useEffect, useState } from 'react'
import Layout from '../../components/Layout'
import PostEntry from '../../components/PostEntry'
import Pagination from '../../components/Pagination'
import SEO from '../../components/Seo'
import { Box, Span } from '../../components/Shared'
import styled from 'styled-components'
import HeadingBanner from '../../layouts/HeadingBanner'
import { Link, navigate } from 'gatsby'

const CategoryFilter = styled(Box)`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  font-weight: 600;
`
const CategorySelector = styled.button`
  border: ${(props) => (props.selected ? '4px solid #E96A4B' : 'none')};
  padding: 1rem;
  font-size: 1.2rem;
  background: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.burntSienna};
`

const Blog = ({ pageContext, location }) => {
  const { nodes, pageNumber, hasNextPage, itemsPerPage, allPosts } = pageContext

  var searchParams = new URLSearchParams(location.search)

  let searchedCategory = searchParams
    .get('category')
    ?.replace(/\b\w/g, (l) => l.toUpperCase())

  const [selectedCategory, setSelectedCategory] = useState(
    searchedCategory ?? null
  )

  const categoryHasPosts = nodes?.some(
    (post) => post.categories.nodes[1]?.name === selectedCategory
  )

  let postsToRender =
    selectedCategory && categoryHasPosts
      ? allPosts
      : selectedCategory && !categoryHasPosts
      ? nodes
      : nodes

  // filter only when a category has been selected
  if (selectedCategory) {
    postsToRender = postsToRender.filter((post) => {
      const category = post.categories.nodes[1]?.name
      if (category === selectedCategory) return post
    })
  }

  let categorySelectedButNoneMatching = selectedCategory && !categoryHasPosts

  const handleCategorySelection = (category) => {
    if (category) {
      navigate(`/blog/?category=${category.toString()}#categories`, {
        state: 'blag',
      })
      setSelectedCategory(category)
    } else {
      navigate(`/blog#categories`)
      setSelectedCategory('')
    }
  }

  return (
    <Layout>
      <SEO title="Blog" description="Blog posts" />
      <HeadingBanner
        heading="The Emancopyco Blog"
        subheadingBottom="(AKA the place where you get free access to all my copy secrets and business strategies)"
      ></HeadingBanner>
      <CategoryFilter
        id="categories"
        bg="mandysPink"
        minHeight="6rem"
        flexWrap="wrap"
        flexDirection={['column', 'column', 'row']}
      >
        <CategorySelector
          selected={selectedCategory === ''}
          onClick={() => handleCategorySelection('')}
        >
          All Categories
        </CategorySelector>
        <CategorySelector
          selected={selectedCategory === 'Email Teardown'}
          onClick={() => handleCategorySelection('Email Teardown')}
        >
          Email Teardown
        </CategorySelector>
        <CategorySelector
          selected={selectedCategory === 'Copy Tips'}
          onClick={() => handleCategorySelection('Copy Tips')}
        >
          Copy Tips
        </CategorySelector>
        <CategorySelector
          selected={selectedCategory === 'All Things Biz'}
          onClick={() => handleCategorySelection('All Things Biz')}
        >
          All Things Biz
        </CategorySelector>
      </CategoryFilter>
      <Box p={[3, 4, 4]}>
        {categorySelectedButNoneMatching ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height="20rem"
          >
            <Span>
              {`No posts found in ${selectedCategory}`}{' '}
              <span aria-label="sad face" role="img">
                😞
              </span>
            </Span>
          </Box>
        ) : (
          <Box
            display="grid"
            gridTemplateColumns={[
              'repeat(1, 1fr)',
              'repeat(2, 1fr)',
              'repeat(3, 1fr)',
            ]}
            gridGap={4}
          >
            {postsToRender.map((post) => (
              <PostEntry key={post.slug} post={post} />
            ))}
          </Box>
        )}
        {!selectedCategory && (
          <Pagination
            pageNumber={pageNumber}
            hasNextPage={hasNextPage}
            itemsPerPage={itemsPerPage}
            searchedCategory={location.search}
          />
        )}
      </Box>
    </Layout>
  )
}

export default Blog
