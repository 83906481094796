import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { blogURI } from '../../../globals'
import { bool, number } from 'prop-types'
import { Box } from '../Shared'

const PaginationWrapper = styled(Box)`
  span {
    font-weight: 600;
    font-size: 1.2rem;
  }

  a:active {
    color: ${(props) => props.theme.colors.toreaBay};
    background-color: transparent;
    text-decoration: underline;
  }
`

const Pagination = ({ pageNumber, hasNextPage }) => {
  if (pageNumber === 1 && !hasNextPage) return null

  return (
    <PaginationWrapper margin="60px auto" textAlign="center">
      <div>
        {pageNumber > 1 ? (
          <Link
            to={
              pageNumber > 2
                ? `/${blogURI}/page/${pageNumber - 1}`
                : `/${blogURI}/`
            }
          >
            <span>Previous page</span>
          </Link>
        ) : null}
        <span aria-current="page" style={{ padding: '5px 10px' }}>
          <span>Page </span>
          {pageNumber}
        </span>
        {hasNextPage ? (
          <Link to={`/${blogURI}/page/${pageNumber + 1}`}>
            <span>Next page </span>
          </Link>
        ) : null}
      </div>
    </PaginationWrapper>
  )
}

Pagination.propTypes = {
  hasNextPage: bool.isRequired,
  pageNumber: number.isRequired,
}

export default Pagination
