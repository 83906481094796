import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Link } from 'gatsby'
import { blogURI } from '../../../globals'
import FluidImage from '../FluidImage'
import { Box, Heading, Span, Spacer } from '../Shared'
import straightUnderline from '../../images/straightUnderline.svg'
import { formatDate } from '../../utils/dateFormatter'

export const Wrapper = styled(Box)`
  position: relative;
  margin-left: auto;
  margin-right: auto;

  a {
    text-decoration: none;
  }

  ${Span} {
    color: black;
    display: block;
    background: url(${straightUnderline});
    background-repeat: repeat-x;
    background-position: 20% 100%;
    background-size: 60% 40%;
    padding-bottom: 1rem;
    width: 100%;
  }
`

const PostEntry = ({ post }) => {
  const { uri, title, previewPostImage, date } = post

  const datePosted = formatDate(date)

  return (
    <Wrapper as="article" p={4}>
      <Link to={`/${blogURI}${uri}`}>
        <FluidImage image={previewPostImage.node} />
        <Spacer my={3} />
        <Span>{datePosted}</Span>
        <Spacer my={3} />
        <Heading as="h2" color="toreaBay" fontWeight={400} fontSize="1.2rem">
          {title}
        </Heading>
      </Link>
    </Wrapper>
  )
}

PostEntry.propTypes = {
  post: PropTypes.object,
}

export default PostEntry
